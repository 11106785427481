import PythonImg  from "../../assests/images/python.png"
import JavaImg from "../../assests/images/java.png"
import clanguage from "../../assests/images/c++.png"

export const COURSE_DETLIS =  [
    { img: PythonImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems"  },
    { img: JavaImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: clanguage, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: PythonImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: JavaImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: clanguage, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: PythonImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: JavaImg, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
    { img: clanguage, title: "Basics of Python with Data Structures and Algorithms [updated in 2023]", time: "60+ Hours", rateting: "4.8", student: "(17K+ student)", problems: "350+ Problems" },
  ]
 export  const COURSE_OPTIONS = [
    { value: "java", label: "Java" },
    { value: "python", label: "Python" },
    { value: "javascript", label: "JavaScript" },
   
  ];
import React, { useState } from 'react'
import ChatProfile from '../../../../assests/images/ChatProfile.png'
import { ReactComponent as AudioCall } from '../../../../assests/images/mobileIcon.svg'
import { ReactComponent as VideoCall } from '../../../../assests/images/videoCall.svg'
import { ReactComponent as MenuIcon } from '../../../../assests/images/MenuIcon.svg'
import { ReactComponent as LinkCopyIcon } from '../../../../assests/images/LinkCopyIcon.svg'
import { ReactComponent as CopyPageIcon } from '../../../../assests/images/copyPageIcon.svg'
import { ReactComponent as MicePhoneIcon } from '../../../../assests/images/micPhone.svg'

import Sidebar from '../../Directive/Sidebar/Index'
import Navbar from '../../Directive/Header/Index'

const chatProfileData = [
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },
    {
        profile: ChatProfile,
        name: 'Joo Muri',
        message: 'Good Morning',
        time: '10:30',
        messageCount: "2"
    },

]

const selectedMessage = [
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hello",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "15 min ago",
        sender: true,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hello",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "15 min ago",
        sender: true,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hello",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "15 min ago",
        sender: true,
    },
    {
        profile: ChatProfile,
        mainMessage: "Hi Joo",
        message: "Our trainers go through a unique selection process to ensure there’s no.",
        time: "5 min ago",
        sender: false,
    },

]
export default function DoubtClearing() {
    const [chatTopButton, setChatTopButton] = useState("students")
    const [openMenu, setOpenMenu] = useState(false)
    return (

                    <div className="main-content max-100-vh overflow-y-hidden ">
                        <Navbar title="Doubt Clearing" />
                        <div className="container-fluid">
                            <div className="row mt-3">
                                <div className="col-lg-5 col-md-12">
                                    <div className='chatPepple'>
                                        <h6>Chat</h6>
                                        <div class="input-group searchBox mb-3">
                                             <input type="text" class="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                        <div className='chatButtonTop'>
                                            <button onClick={() => setChatTopButton('instructors')} className={`${chatTopButton === "instructors" && 'activechatTop'}`}>Instructors</button>
                                            <button onClick={() => setChatTopButton('students')} className={`${chatTopButton === "students" && 'activechatTop'}`}>Students</button>
                                        </div>
                                        <div className='showPepple'>
                                            {
                                                chatProfileData?.map((item, index) => {
                                                    return (
                                                        <div className='chatShowName'>
                                                            <img src={item?.profile} alt={item?.name} />
                                                            <div className='showNameProfile'>
                                                                <div className='showMessageProfile'>
                                                                    <h5>{item?.name}</h5>
                                                                    <h6>{item?.message}</h6>
                                                                </div>
                                                                <div className='showTimeProfile'>
                                                                    <h6>{item?.time}</h6>
                                                                    <span>{item?.messageCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7 col-md-12'>
                                    <div className='chatPersonTop'>
                                        <div className='d-flex justify-content-start align-items-center w-50'>
                                            <div className='profileShow'>
                                                <img src={ChatProfile} />
                                                <div className='proffileOnline'></div>
                                            </div>
                                            <div className='profileNameContent'>
                                                <h6>Joo Muri</h6>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end w-50'>
                                            <div className='callIcon'>
                                                <button className='audioCallIcon'><AudioCall /></button>
                                                <button className='videoCallIcon'><VideoCall /></button>
                                            </div>
                                            <button className='menuicon'> <MenuIcon /></button>
                                        </div>
                                    </div>
                                    <div className='chatMiddle'>
                                        <div>
                                            {
                                                selectedMessage?.map((item, index) => {
                                                    return (
                                                        <div className={`leftSideContent ${item?.sender === true && 'senderChatMessage'}`}>
                                                           <div className='messageShow'>
                                                           <h6>{item?.mainMessage}</h6>
                                                           <p>{item?.message}</p>
                                                            </div>
                                                            <img src={item?.profile} className='messageImgChat'/>
                                                            <span>{item?.time}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='w-10 position-relative'>
                                            {
                                                openMenu === true && (
                                                    <div className='openmenuShow'>
                                                        <button className='copyPageIcon'><CopyPageIcon /></button>
                                                        <button className='linkCopyIcon'><LinkCopyIcon /></button>
                                                        <button className='micePhoneIcon'><MicePhoneIcon /></button>
                                                    </div>
                                                )
                                            }
                                            <button className='menubottom' onClick={() => setOpenMenu(!openMenu)}><MenuIcon /></button>
                                        </div>
                                        <div className='w-100'>
                                            <div className='chatBottomSheet'>
                                                <div class="input-group">
                                                    <span class="input-group-text d-flex justify-content-center align-items-center"><i class="fa-regular fa-face-smile"></i></span>
                                                    <input type="text" class="form-control" placeholder='Type a Message' aria-label="Amount (to the nearest dollar)" />
                                                    <span class="input-group-text d-flex justify-content-center align-items-center"><i class="fa-solid fa-paper-plane text-success"></i></span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          
    )
}

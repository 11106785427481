import React, { useState } from 'react'
import { ReactComponent as AssignmentUpload } from '../../../../assests/images/assignmentUpload.svg'
import { ReactComponent as ViewIcon } from '../../../../assests/images/ViewIcon.svg'
import Sidebar from "../../Directive/Sidebar/Index";
import Navbar from "../../Directive/Header/Index";
export default function ManageAssignment() {
    const [assignment, setAssignment] = useState("manage")

    const manageAssignment = [
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },

        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
        {
            course: "Python",
            UnitLesson: "Lesson 1"
        },
    ]

    const reviewAssignment = [
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },

        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
        {
            course: "Python",
            Student: "Shivam"
        },
    ]
    return (

 
                <div className="main-content">
                    <Navbar title="Assignment" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className='manageAssignment'>
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <div className='manageAssignment-top'>
                                        <button onClick={() => setAssignment("manage")} className={`${assignment === "manage" && "activeClassAssignmemnt"}`}>Manage Assignment</button>
                                        <button onClick={() => setAssignment("review")} className={`${assignment === "review" && "activeClassAssignmemnt"}`}>Review Assignment</button>
                                    </div>
                                    <button className='create-assignment'>Create Assignment</button>
                                </div>
                                {
                                    assignment === "manage" ? (

                                        <div className='showTableAssgiment'>
                                            <table>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Course</th>
                                                    <th>Unit/Lesson</th>
                                                    <th>Assignment</th>
                                                </tr>
                                                {
                                                    manageAssignment?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.course}</td>
                                                                <td>{item?.UnitLesson}</td>
                                                                <td><AssignmentUpload /></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    ) : (

                                        <div className='showTableAssgiment'>
                                            <table>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Student</th>
                                                    <th>Course</th>
                                                    <th>Assignment</th>
                                                </tr>
                                                {
                                                    reviewAssignment?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.Student}</td>
                                                                <td>{item?.course}</td>
                                                                <td><ViewIcon /></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
  

    )
}

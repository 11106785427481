import { configureStore } from "@reduxjs/toolkit"
import studentAuthSlice from "./slice/studentSlice/studentAuthSlice";
import userListAdminSlice from './slice/adminSlice/userListAdminSlice' 
import StudentcourseSclie from "./slice/studentSlice/StudentcourseSclie";

const store = configureStore({
    reducer: {
        // admin penal  
        userListAdmin:userListAdminSlice,
        ///student panel 
        studentAuth: studentAuthSlice,
        studentCourse:StudentcourseSclie
    },

});

export default store;
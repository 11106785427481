import React from 'react'
import {ReactComponent as CalanderIcon} from '../../../../assests/images/calanderIcon.svg'
import {ReactComponent as ClockIcon} from '../../../../assests/images/ClockIcon.svg'
export default function UpcomingCodingExamCard({
    img,
    title,
    date,
    time
}) {
    return (
        <div className='upcomingCard'>
            <img src={img} />
            <h6>{title}</h6>
            <div className='d-flex align-items-center'>
                <CalanderIcon />
                <span>{date}</span>
            </div>
            <div className='d-flex align-items-center'>
                <ClockIcon />
                <span>{time}</span>
            </div>
        </div> 
    )
}

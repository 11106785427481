import React from 'react'
import bells from "../../../../assests/images/bells.jpg";
import inspire from "../../../../assests/images/inspire7.png";  
import "./Header.css";
import Search from "../../../../assests/images/search.jpg";
function Index({title}) {
  return (
    <div className='navbarSticky'> <section className='navbar-sec'>
    <div className='navbar-detail'>
      <div className='navbar-basic'>
        <h3>{title}</h3>
      <img src={Search} alt="Inspire"  className='Searchicon'/>
       
      </div>
      <div className='navbar-info'>
      <img src={bells} alt=""  className='bells'/>
        <img src={inspire} alt="Inspire" />
        <div className="dropdown">
          <h3 className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Joo Muri
          </h3>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section></div>
  )
}

export default Index
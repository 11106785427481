import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assests/images/logo.png";
import "./sidebar.css";
function Index() {

  const [openlist, setOpenList] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [course, setCourse] = useState(false);

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setCourse(true);
  };
  const handleOpenlist = () => {
    setOpenList(!openlist);
  };
  const [aria, setAria] = useState(false);

  const handlearia = () => {
    setAria(true);
  };
  
  return (
    <div className="h-100">
      <div className="d-flex home h-100">
        {/* Sidebar scroll*/}
        <div className="d-flex sidebar-detail flex-column  ">
          <div className="sidebar-img">
            <img src={logo} alt=""></img>
          </div>
          {/* <ul className="nav nav-pills flex-column mb-auto px-x">
            <li className="nav-item">
              <Link
                to="/admin/dashboard"
                className={`nav-link ${
                  activeSection === "dashboard" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("dashboard")}
              >
                <i className="fa-brands fa-slack"></i><span className="sdhead">Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/admin/courses"
                className={`nav-link ${
                  activeSection === "Courses" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Courses")}
              >
                <i className="fa-solid fa-user-group"></i><span className="sdhead"> Courses</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/manageAssignment"
                className={`nav-link ${
                  activeSection === "ManageAssignment" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("ManageAssignment")}
              >
                <i class="fa-solid fa-head-side-virus"></i><span className="sdhead"> Manage Assignment</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/instructors"
                className={`nav-link ${
                  activeSection === "Instructors" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Instructors")}
              >
                <i className="fa-solid fa-cloud"></i> <span className="sdhead">Instructors</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/students"
                className={`nav-link ${
                  activeSection === "Students" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Students")}
              >
                <i className="fa-solid fa-cloud"></i><span className="sdhead"> Students</span>
              </Link>
            </li>
            
            <li className="nav-item">
              <Link
                to="/admin/batchs"
                className={`nav-link ${
                  activeSection === "Batchs" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Batchs")}
              >
                <i className="fa-regular fa-user"></i><span className="sdhead">Batchs</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/syllabus"
                className={`nav-link ${
                  activeSection === "Syllabus" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Syllabus")}
              >
                <i className="fa-regular fa-user"></i><span className="sdhead">Syllabus</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/ctysyllabus"
                className={`nav-link ${
                  activeSection === "Category" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Category")}
              >
                <i className="fa-regular fa-user"></i><span className="sdhead"> Add category Syllabus</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/quizzes"
                className={`nav-link ${
                  activeSection === "Quizzes" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Quizzes")}
              >
                <i className="fa-regular fa-circle-question"></i> <span className="sdhead">Manage Quizzes</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/userlist"
                className={`nav-link ${
                  activeSection === "Users" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Users")}>
                <i className="fa-regular fa-user"></i><span className="sdhead"> All User List</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/edithome"
                className={`nav-link ${activeSection === "Home" ? "active" : ""}`}
                onClick={() => handleSectionClick("Home")}
              >
                <i class="fa-solid fa-wand-magic-sparkles"></i><span className="sdhead">Home Update</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/my_orders"
                className={`nav-link ${
                  activeSection === "MyOrders" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("MyOrders")}
              >
                <i className="fa-brands fa-first-order"></i><span className="sdhead">My Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "Wishlist" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Wishlist")}
              >
                <i className="fa-brands fa-first-order"></i><span className="sdhead">Wishlist</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/attendance"
                className={`nav-link ${
                  activeSection === "attendance" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("attendance")}
              >
                <i className="fa-regular fa-calendar-days"></i><span className="sdhead">Attendance</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "myClasses" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("myClasses")}
              >
                <i class="fa-regular fa-comments"></i><span className="sdhead">My Classes</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/assignment"
                className={`nav-link ${
                  activeSection === "assignments" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("assignments")}
              >
                <i class="fa-regular fa-clipboard"></i><span className="sdhead">Assignments</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "workshopUpdate" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("workshopUpdate")}
              >
                <i class="fa-solid fa-wand-magic-sparkles"></i><span className="sdhead">Workshop Update</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "jobNotification" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("jobNotification")}
              >
                <i class="fa-solid fa-triangle-exclamation"></i><span className="sdhead">Job Notification</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "liveExam" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("liveExam")}
              >
                <i class="fa-solid fa-video"></i><span className="sdhead">Live Exam</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "mockInterview" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("mockInterview")}
              >
                <i class="fa-solid fa-users"></i><span className="sdhead">Mock Interview</span>
              </Link>
            </li>
          </ul> */}
            <ul className="nav nav-pills flex-column mb-auto px-x">
            <li className="nav-item">
              <Link
                to="/admin/dashboard"
                className={`nav-link ${
                  activeSection === "dashboard" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("dashboard")}
              >
                <i className="fa-brands fa-slack"></i>
                <span className="sdhead">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/courses"
                className={`nav-link ${
                  activeSection === "Courses" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Courses")}
              >
                <i className="fa-solid fa-user-group"></i>
                <span className="sdhead"> Courses</span>
                {activeSection === "Courses" && (
                  <ul className="sub-menu ">
                    <li  className=" border-secondary border-bottom">
                      <Link
                        to="/admin/courses/enrolledCourses"
                  
                      >
                        Enrolled Courses
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/courses/instructorNots">
                        Instructor Notes{" "}
                      </Link>
                    </li>
                  </ul>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/doubtClearing"
                className={`nav-link ${
                  activeSection === "doubtClearing" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("doubtClearing")}
              >
                <i className="fa-solid fa-user-group"></i>
                <span className="sdhead">Doubt Clearing</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/admin/myProfile"
                className={`nav-link ${
                  activeSection === "myProfile" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("myProfile")}
              >
                <i className="fa-solid fa-user-group"></i>
                <span className="sdhead">My Profie</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/quizzes"
                className={`nav-link ${
                  activeSection === "Quizzes" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Quizzes")}
              >
                <i className="fa-regular fa-circle-question"></i>{" "}
                <span className="sdhead">Manage Quizzes</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/my_orders"
                className={`nav-link ${
                  activeSection === "MyOrders" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("MyOrders")}
              >
                <i className="fa-brands fa-first-order"></i>
                <span className="sdhead">My Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "Wishlist" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Wishlist")}
              >
                <i className="fa-brands fa-first-order"></i>
                <span className="sdhead">Wishlist</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/attendance"
                className={`nav-link ${
                  activeSection === "attendance" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("attendance")}
              >
                <i className="fa-regular fa-calendar-days"></i>
                <span className="sdhead">Attendance</span>
              </Link>
            </li>
              
            <li className="nav-item">
              <Link
                to="/admin/myclasses"
                className={`nav-link ${
                  activeSection === "myClasses" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("myClasses")}
              >
                <i class="fa-regular fa-comments"></i>
                <span className="sdhead">My Classes</span>
              </Link>
            </li>
            
            <li className="nav-item">
              <Link
                to="/admin/assignment"
                className={`nav-link ${
                  activeSection === "assignments" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("assignments")}
              >
                <i class="fa-regular fa-clipboard"></i>
                <span className="sdhead">Assignments</span>
              </Link>
            </li>



            <li className="nav-item">
              <Link
                to="/raksha"
                className={`nav-link ${
                  activeSection === "workshopUpdate" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("workshopUpdate")}
              >
                <i class="fa-solid fa-wand-magic-sparkles"></i>
                <span className="sdhead">Workshop Update</span>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                to="/admin/jobNotification"
                className={`nav-link ${
                  activeSection === "jobNotification" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("jobNotification")}
              >
                <i class="fa-solid fa-triangle-exclamation"></i>
                <span className="sdhead">Job Notification</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/liveExam"
                className={`nav-link ${
                  activeSection === "liveExam" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("liveExam")}
              >
                <i class="fa-solid fa-video"></i>
                <span className="sdhead">Live Exam</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/admin/mockInterview"
                className={`nav-link ${
                  activeSection === "mockInterview" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("mockInterview")}
              >
                <i class="fa-solid fa-users"></i>
                <span className="sdhead">Mock Interview</span>
              </Link>
            </li>

{/*             
            <li className="nav-item">
              <Link
                to="/admin/instructors"
                className={`nav-link ${
                  activeSection === "Instructors" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Instructors")}
              >
                <i className="fa-solid fa-cloud"></i>{" "}
                <span className="sdhead">Instructors</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/students"
                className={`nav-link ${
                  activeSection === "Students" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Students")}
              >
                <i className="fa-solid fa-cloud"></i>
                <span className="sdhead"> Students</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/batchs"
                className={`nav-link ${
                  activeSection === "Batchs" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Batchs")}
              >
                <i className="fa-regular fa-user"></i>
                <span className="sdhead">Batchs</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/syllabus"
                className={`nav-link ${
                  activeSection === "Syllabus" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Syllabus")}
              >
                <i className="fa-regular fa-user"></i>
                <span className="sdhead">Syllabus</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/ctysyllabus"
                className={`nav-link ${
                  activeSection === "Category" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Category")}
              >
                <i className="fa-regular fa-user"></i>
                <span className="sdhead"> Add category Syllabus</span>
              </Link>
            </li>
         
            <li className="nav-item">
              <Link
                to="/admin/manageAttendance"
                className={`nav-link ${
                  activeSection === "manageAttendance" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("manageAttendance")}
              >
                <i class="fa-regular fa-calendar-days"></i>
                <span className="sdhead">Manage Attendance</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/leaveAttendance"
                className={`nav-link ${
                  activeSection === "leaveAttendance" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("leaveAttendance")}
              >
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <span className="sdhead">Leave Attendance</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/userlist"
                className={`nav-link ${
                  activeSection === "Users" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Users")}
              >
                <i className="fa-regular fa-user"></i>
                <span className="sdhead"> All User List</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/edithome"
                className={`nav-link ${
                  activeSection === "Home" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Home")}
              >
                <i class="fa-solid fa-wand-magic-sparkles"></i>
                <span className="sdhead">Home Update</span>
              </Link>
            </li> */}
          
       
         
           
        
     
       
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Index;

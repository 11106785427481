import React from 'react'
import Sidebar from '../../Directive/Sidebar/Index'
import Navbar from '../../Directive/Header/Index'

const leaveAttendanceData=[
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Present",
    },

    {
        student:"Shivam",
        status:"Present",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
    {
        student:"Shivam",
        status:"Absent",
    },
]
export default function ManageAttendance() {
    return (
    
                    <div className="main-content">
                        <Navbar title="Manage Attendance" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='leaveAttendanceBox'>
                                        <div className="text-center">
                                            <table>
                                                <tr>
                                                    <th  className="fw-bold" style={{fontSize:"20px"}}>Sr. No.</th>
                                                    <th  className="fw-bold" style={{fontSize:"20px"}}>Student</th>
                                                    <th  className="fw-bold" style={{fontSize:"20px"}}>Status</th>
                                                    <th  className="fw-bold" style={{fontSize:"20px"}}>Action</th>
                                                </tr>
                                                {
                                                    leaveAttendanceData?.map((item,index)=>{
                                                        return(
                                                            <>
                                                            <tr>
                                                                <td className="text-center">{index+1}.</td>
                                                                <td className="text-center">{item?.student}</td>
                                                                <td className={`text-center ${item?.status === 'Present' ? 'present' : 'absent'}`}>{item?.status}</td>
                                                                <td className="text-center">
                                                                    <div>
                                                                        <button className='presentbtn'>Present</button>
                                                                        <button className='absentbtn'>Absent</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                                <tr></tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
       
    )
}

import React, {  useState } from "react";
import Navbar from "../../AdminPannel/Directive/Header/Index";
import { COURSE_DETLIS, COURSE_OPTIONS } from "../constant";
import "./style.css";

import SingleCourse from "./SingleCourse/indesx";

import Select from "react-select";

const Course = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "20px",
      border: "1px solid #D9D9D9",
      background: "#FFF",
      boxShadow: "0px 0px 20px 0px rgba(232, 232, 232, 0.70)",
      width: "230px",
      height: "42px",
      flexShrink: "0",
    }),
  };
    
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="main-content ">
      <Navbar title="Courses" />
      <div className="container-fluid d-grid gap-2 mt-3 px-4">
        <div className="col-2 ">
        <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={COURSE_OPTIONS}
                        placeholder="All Course"
                        styles={customStyles}
                      />
        </div>
        <div className="row ">
          {COURSE_DETLIS.map((course, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 align-items-center d-flex justify-content-center ">
              <SingleCourse
                image={course.img}
                problem={course.problems}
                ratting={course.rateting}
                students={course.student}
                text={course.title}
                time={course.time}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Course;

import { ReactComponent as CourseIcon } from "../../../../assests/images/courseIcon.svg";
import { ReactComponent as StarRatingIcon } from "../../../../assests/images/starRatingIcon.svg";
import { ReactComponent as Clock } from "../../../../assests/images/ClockIcon.svg";
const SingleCourse = ({
    text, image ,time , ratting ,students,problem
}) => {
return (
   
    <div className="card border rounded-4 w-100 overflow-hidden " style={{height:"90%"}}>
      <div className="row justify-content-between align-itme-center cardTopSession p-4 mb-2">
        <div className="col-8 text-wrap">
          <p className=" fs-6 text-weight">
       {text}
          </p>
        </div>
        <div className="col-4 d-flex align-itmes-center">
          <div className="w-100 h-50 mx-auto d-flex justify-content-center align-items-center">
            <img src={image} className="w-full h-full" />
          </div>
        </div>
      </div>
      <div className="row justify-content-between align-items-center pb-3 px-1 ">
        <div className="col-6 px-0 d-flex align-items-center gap-2 justify-content-center">
          <Clock />
          <p className="text-muted">{time}</p>
        </div>
        <div className="col-6 px-0 d-flex  align-items-center gap-2 ">
          <CourseIcon />
          <p className="text-Secondary">{problem}</p>
        </div>
      </div>
      <div className="px-4 pb-4 d-flex align-items-center ">
        <p className="d-flex align-items-center" >
          <span className="Ratting_Font_Size text-weight">{ratting}</span>
          <StarRatingIcon />
          <StarRatingIcon />
          <StarRatingIcon />
          <StarRatingIcon />
          <StarRatingIcon />
          <span className="Ratting_Font_Size ">(`{students}`)</span>
        </p>
      </div>
    </div>
  
)
}
export default SingleCourse 
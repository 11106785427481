  import React from "react";
  import Sidebar from "../../Directive/Sidebar/Index";
  import Navbar from "../../Directive/Header/Index";
  import DashboardCard from "../../dashboardCard/DashboardCard";
  import adminDashboard from "../../../../assests/images/adminDashboard.png";
  import adminDashboard1 from "../../../../assests/images/admindashboard1.png";
  import adminDashboard2 from "../../../../assests/images/admindashboard2.png";
  import paythan from "../../../../assests/images/python.png"
  import java from "../../../../assests/images/java.png"
  import { ReactComponent as CalanderIcon } from '../../../../assests/images/calanderIcon.svg'
  import { ReactComponent as LocationIcon } from '../../../../assests/images/locationIcon.svg'
  import cpp from "../../../../assests/images/c++.png"
  import { ReactComponent as AchievementImg } from '../../../../assests/images/achievementDash.svg'
  import UpcomingCodingExamCard from "./UpcomingCodingExamCard";
  import { ReactComponent as UpcomingDashEvent } from '../../../../assests/images/upcomingDashEvent.svg'
  import CalendarCom from "./CalendarCom";
import PerformanceChart from "./PerformanceChart";

  function Index() {
    return (
     
          <div className="main-content">
            <Navbar title="Dashboard" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-12"></div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <DashboardCard
                      title="Enrolled Courses"
                      img={adminDashboard2}
                      count="100"
                      className="#feede3"
                      borderColor="#F37321"
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <DashboardCard
                      title="Pending Assignment"
                      img={adminDashboard1}
                      count="100"
                      className="#E2FEF6"
                      borderColor="#20FFBF"
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <DashboardCard
                      title="Pending Assignment"
                      img={adminDashboard}
                      count="100"
                      className="#E2FCFE"
                      borderColor="#21F0FD"
                    />
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div style={{ width: "68%",display:"flex",justifyContent:"flex-start", padding: "0 25px" }}>
                  <PerformanceChart />
                  </div>
                  <div className="d-flex justify-content-center align-items-center" style={{ width: "32%",padding:"0px" }}>
                    <CalendarCom />
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div style={{ width: "66%" }}>
                   <div  className="bg-white border rounded-3 px-2">
                   <div className="row justify-content-around align-items-center mt-3">
                      <p className="w-50 font-bold h5 ">Upcoming Coding Exam</p>
                      <p className="w-50 codeExamTextColor text-end mb-0">ALL</p>
                    </div>
                    <div className="row">
                      <div >
                        <UpcomingCodingExamCard img={paythan} title={"Basics of Python"} date={"August 9, 2023"} time={"09:00 AM"} />
                        <UpcomingCodingExamCard img={java} title={"Basics of Python"} date={"August 9, 2023"} time={"09:00 AM"} />
                        <UpcomingCodingExamCard img={cpp} title={"Basics of Python"} date={"August 9, 2023"} time={"09:00 AM"} />
                      </div>
                    </div>
                   </div>
                  </div>
                  <div style={{ width: "32%" }}>
                    <div className="installmentDate">
                      <h6>1st Installment Date Coming soon - 18-09-23</h6>
                    </div>
                    <div className="achievement">
                      <h6>Achievement</h6>
                      <div className="d-flex mt-3 justify-content-left align-items-center">
                        <AchievementImg />
                        <h5>Last Exam Rank : -<span>23</span></h5>
                      </div>
                    </div>
                    <div className="upcomingevent">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>Upcoming Event</h6>
                        <span>All</span>
                      </div>
                      <div className="d-flex boxupcomingevent mt-3 justify-content-left align-items-center">
                        <UpcomingDashEvent />
                        <div className="titleShow">
                          <h6>Event Your Career
                            Next Leveley Futu</h6>
                          <h5>09:00 AM - 11:00 AM</h5>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div className="calanderShowBox">
                            <CalanderIcon />
                            <span>August 9, 2023</span>
                          </div>
                          <div className="locationShowBox">
                            <LocationIcon />
                            <span>Melbourne, Austra</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
    );
  }

  export default Index;

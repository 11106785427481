import Navbar from "../../AdminPannel/Directive/Header/Index";
import SingleQuizzes from "./SingleQuizzes";
import "./style.css";
const Quizzes = () => {
  return (
    <div className="main-content">
      <Navbar title="Quizze" />
      <div className="container-fluid d-grid gap-2 mt-3 px-4">
        <div className="row ">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12 col-md-6 ">
                <div className="d-flex gap-2 flex-column">
                  <SingleQuizzes />
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div className="d-flex gap-2 flex-column">
                  <SingleQuizzes  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Quizzes;

import React from 'react'
import ReactPlayer from 'react-player'
import Navbar from '../../../../component/AdminPannel/Directive/Header/Index'
import { ReactComponent as AvtarNew } from '../../../../assests/images/img/avatars/avtarNew.svg'
import { ReactComponent as UploadImg } from '../../../../assests/images/uploadImg.svg'
import { ReactComponent as EmojisIcon } from '../../../../assests/images/emojis.svg'
import { ReactComponent as Time } from '../../../../assests/images/timeWatch.svg'

const liveClassesData = [
  {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  },
  {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  }, {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  }, {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  }, {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  }, {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  }, {
    img: <AvtarNew />,
    name: "Jack Freyaed : ",
    description: "good explanation"
  },
]
export default function ManageLiveAndRecordingClasses() {
  return (
    <div className="main-content myClasses">
      <Navbar title="Manage Live And Recording Classes" />
      <div className="container-fluid">
        <div className='row'>
          <div className='col-8'>
            <div className='liveClasses'>
              <ReactPlayer
                url='https://www.youtube.com/watch?v=4DUX-Uzddus'
                controls
                width="100%"
                onReady={() => console.log('onReady callback')}
                onStart={() => console.log('onStart callback')}
                onPause={() => console.log('onPause callback')}
                onEnded={() => console.log('onEnded callback')}
                onError={() => console.log('onError callback')}
              />
              <div className='videoTitle'>
                <div className='titleHedVideo'>
                  <h4>Introduction to Unit - 01 </h4>
                  <div><span>10:00 AM|01:00 PM</span> <Time /></div>
                </div>
              </div>
              <h5>Mealth shawn</h5>
              <p>Our business analyst Master’s program is led by industry experts who will
                make you proficient in the field of busi</p>
              <div className='topicClassesBox'>
                <div className='topicClassesBoxHead'>
                  <h6>Topic - 02</h6>
                  <div className='topicClassesBoxHeadRight'>
                    <span>10:00 AM|01:00 PM</span>
                    <button><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9312 12.0289C11.0813 12.179 11.2655 12.254 11.4838 12.254C11.7021 12.254 11.8931 12.1722 12.0568 12.0084C12.2069 11.8584 12.2819 11.6674 12.2819 11.4354C12.2819 11.2035 12.2069 11.0125 12.0568 10.8624L9.60105 8.4067V5.43935C9.60105 5.20742 9.52274 5.01642 9.36612 4.86635C9.20895 4.71627 9.0144 4.64124 8.78247 4.64124C8.55054 4.64124 8.35627 4.71955 8.19964 4.87617C8.04248 5.03334 7.96389 5.22788 7.96389 5.45982V8.71367C7.96389 8.82281 7.98436 8.92841 8.02529 9.03046C8.06621 9.13305 8.12761 9.22528 8.20947 9.30714L10.9312 12.0289ZM8.78247 16.9199C7.6501 16.9199 6.58595 16.7049 5.59001 16.2749C4.59407 15.8454 3.72775 15.2623 2.99102 14.5256C2.2543 13.7889 1.6712 12.9225 1.24172 11.9266C0.811693 10.9307 0.59668 9.8665 0.59668 8.73413C0.59668 7.60176 0.811693 6.53761 1.24172 5.54167C1.6712 4.54574 2.2543 3.67941 2.99102 2.94268C3.72775 2.20596 4.59407 1.62259 5.59001 1.19256C6.58595 0.76308 7.6501 0.54834 8.78247 0.54834C9.91484 0.54834 10.979 0.76308 11.9749 1.19256C12.9709 1.62259 13.8372 2.20596 14.5739 2.94268C15.3106 3.67941 15.8937 4.54574 16.3232 5.54167C16.7533 6.53761 16.9683 7.60176 16.9683 8.73413C16.9683 9.8665 16.7533 10.9307 16.3232 11.9266C15.8937 12.9225 15.3106 13.7889 14.5739 14.5256C13.8372 15.2623 12.9709 15.8454 11.9749 16.2749C10.979 16.7049 9.91484 16.9199 8.78247 16.9199ZM8.78247 15.2828C10.597 15.2828 12.1422 14.6451 13.4181 13.3697C14.6934 12.0939 15.3311 10.5486 15.3311 8.73413C15.3311 6.91961 14.6934 5.37441 13.4181 4.09852C12.1422 2.82317 10.597 2.1855 8.78247 2.1855C6.96795 2.1855 5.42302 2.82317 4.14768 4.09852C2.87178 5.37441 2.23384 6.91961 2.23384 8.73413C2.23384 10.5486 2.87178 12.0939 4.14768 13.3697C5.42302 14.6451 6.96795 15.2828 8.78247 15.2828Z" fill="#92929D" />
                    </svg>
                    </button>
                  </div>
                </div>
                <h4>Mealth shawn</h4>
              </div>
              <div className='topicClassesBox'>
                <div className='topicClassesBoxHead'>
                  <h6>Topic - 03</h6>
                  <div className='topicClassesBoxHeadRight'>
                    <span>10:00 AM|01:00 PM</span>
                    <button><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9312 12.0289C11.0813 12.179 11.2655 12.254 11.4838 12.254C11.7021 12.254 11.8931 12.1722 12.0568 12.0084C12.2069 11.8584 12.2819 11.6674 12.2819 11.4354C12.2819 11.2035 12.2069 11.0125 12.0568 10.8624L9.60105 8.4067V5.43935C9.60105 5.20742 9.52274 5.01642 9.36612 4.86635C9.20895 4.71627 9.0144 4.64124 8.78247 4.64124C8.55054 4.64124 8.35627 4.71955 8.19964 4.87617C8.04248 5.03334 7.96389 5.22788 7.96389 5.45982V8.71367C7.96389 8.82281 7.98436 8.92841 8.02529 9.03046C8.06621 9.13305 8.12761 9.22528 8.20947 9.30714L10.9312 12.0289ZM8.78247 16.9199C7.6501 16.9199 6.58595 16.7049 5.59001 16.2749C4.59407 15.8454 3.72775 15.2623 2.99102 14.5256C2.2543 13.7889 1.6712 12.9225 1.24172 11.9266C0.811693 10.9307 0.59668 9.8665 0.59668 8.73413C0.59668 7.60176 0.811693 6.53761 1.24172 5.54167C1.6712 4.54574 2.2543 3.67941 2.99102 2.94268C3.72775 2.20596 4.59407 1.62259 5.59001 1.19256C6.58595 0.76308 7.6501 0.54834 8.78247 0.54834C9.91484 0.54834 10.979 0.76308 11.9749 1.19256C12.9709 1.62259 13.8372 2.20596 14.5739 2.94268C15.3106 3.67941 15.8937 4.54574 16.3232 5.54167C16.7533 6.53761 16.9683 7.60176 16.9683 8.73413C16.9683 9.8665 16.7533 10.9307 16.3232 11.9266C15.8937 12.9225 15.3106 13.7889 14.5739 14.5256C13.8372 15.2623 12.9709 15.8454 11.9749 16.2749C10.979 16.7049 9.91484 16.9199 8.78247 16.9199ZM8.78247 15.2828C10.597 15.2828 12.1422 14.6451 13.4181 13.3697C14.6934 12.0939 15.3311 10.5486 15.3311 8.73413C15.3311 6.91961 14.6934 5.37441 13.4181 4.09852C12.1422 2.82317 10.597 2.1855 8.78247 2.1855C6.96795 2.1855 5.42302 2.82317 4.14768 4.09852C2.87178 5.37441 2.23384 6.91961 2.23384 8.73413C2.23384 10.5486 2.87178 12.0939 4.14768 13.3697C5.42302 14.6451 6.96795 15.2828 8.78247 15.2828Z" fill="#92929D" />
                    </svg>
                    </button>
                  </div>
                </div>
                <h4>Mealth shawn</h4>
              </div>
              <div className='topicClassesBox'>
                <div className='topicClassesBoxHead'>
                  <h6>Topic - 04</h6>
                  <div className='topicClassesBoxHeadRight'>
                    <span>10:00 AM|01:00 PM</span>
                    <button><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9312 12.0289C11.0813 12.179 11.2655 12.254 11.4838 12.254C11.7021 12.254 11.8931 12.1722 12.0568 12.0084C12.2069 11.8584 12.2819 11.6674 12.2819 11.4354C12.2819 11.2035 12.2069 11.0125 12.0568 10.8624L9.60105 8.4067V5.43935C9.60105 5.20742 9.52274 5.01642 9.36612 4.86635C9.20895 4.71627 9.0144 4.64124 8.78247 4.64124C8.55054 4.64124 8.35627 4.71955 8.19964 4.87617C8.04248 5.03334 7.96389 5.22788 7.96389 5.45982V8.71367C7.96389 8.82281 7.98436 8.92841 8.02529 9.03046C8.06621 9.13305 8.12761 9.22528 8.20947 9.30714L10.9312 12.0289ZM8.78247 16.9199C7.6501 16.9199 6.58595 16.7049 5.59001 16.2749C4.59407 15.8454 3.72775 15.2623 2.99102 14.5256C2.2543 13.7889 1.6712 12.9225 1.24172 11.9266C0.811693 10.9307 0.59668 9.8665 0.59668 8.73413C0.59668 7.60176 0.811693 6.53761 1.24172 5.54167C1.6712 4.54574 2.2543 3.67941 2.99102 2.94268C3.72775 2.20596 4.59407 1.62259 5.59001 1.19256C6.58595 0.76308 7.6501 0.54834 8.78247 0.54834C9.91484 0.54834 10.979 0.76308 11.9749 1.19256C12.9709 1.62259 13.8372 2.20596 14.5739 2.94268C15.3106 3.67941 15.8937 4.54574 16.3232 5.54167C16.7533 6.53761 16.9683 7.60176 16.9683 8.73413C16.9683 9.8665 16.7533 10.9307 16.3232 11.9266C15.8937 12.9225 15.3106 13.7889 14.5739 14.5256C13.8372 15.2623 12.9709 15.8454 11.9749 16.2749C10.979 16.7049 9.91484 16.9199 8.78247 16.9199ZM8.78247 15.2828C10.597 15.2828 12.1422 14.6451 13.4181 13.3697C14.6934 12.0939 15.3311 10.5486 15.3311 8.73413C15.3311 6.91961 14.6934 5.37441 13.4181 4.09852C12.1422 2.82317 10.597 2.1855 8.78247 2.1855C6.96795 2.1855 5.42302 2.82317 4.14768 4.09852C2.87178 5.37441 2.23384 6.91961 2.23384 8.73413C2.23384 10.5486 2.87178 12.0939 4.14768 13.3697C5.42302 14.6451 6.96795 15.2828 8.78247 15.2828Z" fill="#92929D" />
                    </svg>
                    </button>
                  </div>
                </div>
                <h4>Mealth shawn</h4>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className='chatLiveClasses'>
              {
                liveClassesData?.map((item) => {
                  return (
                    <>
                      <div className='showDescription'>
                        {item?.img}
                        <h6>{item?.name}</h6>
                        <span>{item?.description}</span>
                      </div>
                    </>
                  )
                })
              }
              <div className='chatliveBottom'>
                <input type='text' placeholder='Write or attech something' />
                <div className='buttonIcon'>
                  <EmojisIcon />
                  <UploadImg />
                </div>
              </div>
            </div>
            <div className='buttonShowBottom'>
              <button className='sendButton'>Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

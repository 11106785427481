import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';

const weeklyData = [
  { name: 'Sat', value: 3 },
  { name: 'Sun', value: 5 },
  { name: 'Mon', value: 7 },
  { name: 'Tue', value: 6 },
  { name: 'Wed', value: 4 },
  { name: 'Thu', value: 8 },
  { name: 'Fri', value: 6 },
];

const dailyData = [
  { name: '12 AM', value: 2 },
  { name: '4 AM', value: 4 },
  { name: '8 AM', value: 5 },
  { name: '12 PM', value: 7 },
  { name: '4 PM', value: 3 },
  { name: '8 PM', value: 6 },
  { name: '11 PM', value: 5 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ background: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <p style={{ margin: 0 }}>{`${label}: ${payload[0].value} Task`}</p>
      </div>
    );
  }
  return null;
};

const PerformanceChart = () => {
  const [data, setData] = useState(weeklyData);
  const [selectedRange, setSelectedRange] = useState('Weekly');

  const handleRangeChange = (event) => {
    const range = event.target.value;
    setSelectedRange(range);
    setData(range === 'Weekly' ? weeklyData : dailyData);
  };

  return (
    <div className='performceChart' >
     <div className='d-flex justify-content-between'>
     <h3 style={{ textAlign: 'left', marginBottom: '20px' }}>Performance Analysis</h3>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <select value={selectedRange} onChange={handleRangeChange} style={{ padding: '5px 10px', borderRadius: '5px', border: '1px solid #ccc' }}>
          <option value="Weekly">Weekly</option>
          <option value="Daily">Daily</option>
        </select>
      </div>
     </div>
      <ResponsiveContainer width="100%" height={220}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 0 }}>
          <defs>
            <linearGradient id="colorLine" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#FFA500" />
              <stop offset="100%" stopColor="#FF4500" />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" tick={{ fill: '#7E8C99' }} />
          <YAxis hide />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="url(#colorLine)"
            strokeWidth={3}
            dot={{ r: 6, fill: '#FFA500', strokeWidth: 2, stroke: '#FFF' }}
            activeDot={{ r: 8, stroke: '#FFA500', strokeWidth: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceChart;

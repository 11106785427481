import TProfile from '../AdminPannel/Pages/Profile/index'
import TMyOrders from '../AdminPannel/myorders/MyOrders'
import React from 'react'
import { Routes, Route } from 'react-router-dom';
import TDashboard from '../AdminPannel/Pages/Dashboard/Index';
import TCourse from '../StudentPannel/course/index';
import TQuizzes from '../StudentPannel/Quizzes/index'
import TDoubtClearing from '../AdminPannel/Pages/doubtClearing/DoubtClearing';
import TManageAssignment from '../AdminPannel/Pages/ManageAssignment/ManageAssignment'
import TManageAttendance from '../AdminPannel/Pages/ManageAttendance/ManageAttendance'
import TLeaveManagement from './Page/leaveManagement/LeaveManagement'
import TManageLiveCodingExam from './Page/ManageLiveCodingExam/ManageLiveCodingExam'
import TMockInterviewSchedule from './Page/mockInterviewSchedule/MockInterviewSchedule'
import TNotificationsFromAdmin from './Page/notificationsFromAdmin/NotificationsFromAdmin'
import TPopNotifications from './Page/popNotifications/PopNotifications'
import TManageLiveAndRecordingClasses from './Page/manageLiveAndRecordingClasses/ManageLiveAndRecordingClasses'
import TTrainerLeaveManagementToAdmin from './Page/TrainerLeaveManagementToAdmin/TrainerLeaveManagementToAdmin'





// import Course from '../StudentPannel/course/index';
export default function TrainerApp() {
    return (
        <>
            <Routes>
                {/* <Route path="myProfile" element={<TProfile />} /> */}
                {/* <Route path="my_orders" element={<TMyOrders />} /> */}
                <Route path="dashboard" element={<TDashboard />} />
                <Route path="courses" element={<TCourse />} />
                <Route path="quizzes" element={<TQuizzes />} />
                <Route path="manageAssignment" element={<TManageAssignment />} />
                <Route path="manageAttendance" element={<TManageAttendance />} />
                <Route path="leaveManagement" element={<TLeaveManagement />} />
                <Route path="doubtClearing" element={<TDoubtClearing />} />
                <Route path="manageLiveCodingExam" element={<TManageLiveCodingExam />} />
                <Route path="trainerLeaveManagementToAdmin" element={<TTrainerLeaveManagementToAdmin />} />
                <Route path="mockInterviewSchedule" element={<TMockInterviewSchedule />} />
                <Route path="notificationsFromAdmin" element={<TNotificationsFromAdmin />} />
                <Route path="popNotifications" element={<TPopNotifications />} />
                <Route path="manageLiveClassesandRecordingClasses" element={<TManageLiveAndRecordingClasses />} />
            </Routes>
        </>
    )
}

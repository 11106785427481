import React from 'react'
import Sidebar from '../../Directive/Sidebar/Index'
import Navbar from '../../Directive/Header/Index'

const leaveAttendanceData=[
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Approve",
    },

    {
        student:"Shivam",
        status:"Approve",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
    {
        student:"Shivam",
        status:"Disapprove",
    },
]
export default function LeaveAttendance() {
    return (

                    <div className="main-content">
                        <Navbar title="Leave Attendance" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='leaveAttendanceBox'>
                                        <table>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Student</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {
                                                leaveAttendanceData?.map((item,index)=>{
                                                    return(
                                                        <>
                                                        <tr>
                                                            <td>{index+1}.</td>
                                                            <td>{item?.student}</td>
                                                            <td className={`${item?.status === 'Approve' ? 'present' : 'absent'}`}>{item?.status}</td>
                                                            <td>
                                                                <div>
                                                                    <button className='presentbtn'>Approve</button>
                                                                    <button className='absentbtn'>Disapprove</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                            <tr></tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          
    )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { apiInstance, apiInstanceFetch } from "../../../api/axiosApi";
import { SetDevKey, setToken } from "../../../util/setAuth";
import { secretKey } from "../../../util/config";

import { DangerRight, Success } from "../../../api/toastServices";

const initialState = {
    allUserListData: [],
    isLoading: false,
};

export const allUserList = createAsyncThunk("users/getAllUser", async (payload) => {
    return apiInstanceFetch.get("users/getAllUser");
});

export const createStudent = createAsyncThunk("student/addStudent", async (payload) => {
    return apiInstance.post("student/addStudent",payload);
});



const userListAdminSlice = createSlice({
    name: "userListAdmin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(allUserList.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(allUserList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allUserListData = action.payload.data?.usersData;
        });

        builder.addCase(allUserList.rejected, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(createStudent.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(createStudent.fulfilled, (state, action) => {
            state.isLoading = false;
            if(action.payload.data){
                state.allUserListData?.usersData?.unshift(action.payload.data);
            }
        });

        builder.addCase(createStudent.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
});
export default userListAdminSlice.reducer;
import React, { useState } from "react";
import Sidebar from "../../Directive/Sidebar/Index";
import Navbar from "../../Directive/Header/Index";
import { ReactComponent as CalanderIcon } from "../../../../assests/images/calanderIcon.svg";
import profileImage from "../../../../assests/images/profileImage.png";

function Profile() {
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [name, setName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [studentPhoneNo, setStudentPhoneNo] = useState("");
  const [parentsNo, setParentsNo] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [qualification, setQualification] = useState("");
  const [departments, setDepartments] = useState("");
  const [passedOutYear, setPassedOutYear] = useState("");
  const [address, setAddress] = useState("");
  const [registeredCourseWithPrice, setRegisteredCourseWithPrice] = useState("");
  const [errorMessages, setErrorMessages] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImagePath(e.target.result);
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  const validateForm = () => {
    const errors = {};
    const fields = [
      { name: "name", value: name, message: "Name is required" },
      { name: "email", value: email, message: "Email is required" },
      { name: "fatherName", value: fatherName, message: "Father Name is required" },
      { name: "motherName", value: motherName, message: "Mother Name is required" },
      { name: "studentPhoneNo", value: studentPhoneNo, message: "Student Phone Number is required" },
      { name: "parentsNo", value: parentsNo, message: "Parents No is required" },
      { name: "collegeName", value: collegeName, message: "College Name is required" },
      { name: "qualification", value: qualification, message: "Qualification is required" },
      { name: "departments", value: departments, message: "Departments is required" },
      { name: "passedOutYear", value: passedOutYear, message: "Passed Out Year is required" },
      { name: "address", value: address, message: "Address is required" },
      { name: "registeredCourseWithPrice", value: registeredCourseWithPrice, message: "Registered Course With Price is required" },
    ];

    fields.forEach(({ name, value, message }) => {
      if (!value) errors[name] = message;
    });

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const handelSubmit = async (e) => {
    console.log('name', name)
    e.preventDefault();

    if (validateForm()) {
      // API call logic here
    }
  }

  return (
    <div className="main-content">
      <Navbar title="My Profile" />
      <div className="container mt-5 p-4 ">
        <div className="Order-detail p-4">
          <div className="text-center mb-4">
            <div className="position-relative d-inline-block">
              <input
                type="file"
                id="profileImage"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <label htmlFor="profileImage">
                <img
                  src={imagePath ? imagePath : profileImage}
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "4px solid orange",
                  }}
                />
              </label>
              <span className="position-absolute profileButton bottom-0 end-0 rounded-circle p-2 d-flex justify-content-center align-items-center">
                <i
                  class="fa-solid fa-camera text-white"
                  style={{ fontSize: "15px" }}
                ></i>
              </span>
            </div>
          </div>
          <form>
            <div className="row g-3">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errorMessages.name && <p className="error-message">{errorMessages.name}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Father Name"
                  name="fatherName"
                  value={fatherName}
                  onChange={(e) => setFatherName(e.target.value)}
                />
                {errorMessages.fatherName && <p className="error-message">{errorMessages.fatherName}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mother Name"
                  name="motherName"
                  value={motherName}
                  onChange={(e) => setMotherName(e.target.value)}
                />
                {errorMessages.motherName && <p className="error-message">{errorMessages.motherName}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errorMessages.email && <p className="error-message">{errorMessages.email}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Student Phone No."
                  name="studentPhoneNo"
                  value={studentPhoneNo}
                  onChange={(e) => setStudentPhoneNo(e.target.value)}
                />
                {errorMessages.studentPhoneNo && <p className="error-message">{errorMessages.studentPhoneNo}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Parents No."
                  name="parentsNo"
                  value={parentsNo}
                  onChange={(e) => setParentsNo(e.target.value)}
                />
                {errorMessages.parentsNo && <p className="error-message">{errorMessages.parentsNo}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="College Name"
                  name="collegeName"
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                />
                {errorMessages.collegeName && <p className="error-message">{errorMessages.collegeName}</p>}
              </div>
              <div className="col-md-4">
                <select className="form-select" name="qualification" value={qualification}   onChange={(e) => setQualification(e.target.value)}>
                  <option selected>Qualification</option>
                  <option value="1">Bachelor's</option>
                  <option value="2">Master's</option>
                  <option value="3">PhD</option>
                </select>
                {errorMessages.qualification && <p className="error-message">{errorMessages.qualification}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Departments"
                  name="departments"
                  value={departments}
                  onChange={(e) => setDepartments(e.target.value)}
                />
                {errorMessages.departments && <p className="error-message">{errorMessages.departments}</p>}
              </div>
              <div className="col-md-4">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Passed-out Year"
                    name="passedOutYear"
                    value={passedOutYear}
                    onChange={(e) => setPassedOutYear(e.target.value)}
                  />
                  <span className="input-group-text">
                    <CalanderIcon />
                  </span>
                </div>
                {errorMessages.passedOutYear && <p className="error-message">{errorMessages.passedOutYear}</p>}
              </div>
              <div className="col-md-4">
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                {errorMessages.address && <p className="error-message">{errorMessages.address}</p>}
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Registered course with price"
                  name="registeredCourseWithPrice"
                  value={registeredCourseWithPrice}
                  onChange={(e) => setRegisteredCourseWithPrice(e.target.value)}
                />
                {errorMessages.registeredCourseWithPrice && <p className="error-message">{errorMessages.registeredCourseWithPrice}</p>}
              </div>
            </div>
            <div className="text-end mt-4 ">
              <button
                type="button"
                onClick={handelSubmit}
                className="btn profileButton px-4 text-white"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;

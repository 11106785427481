import React from 'react'
import Navbar from '../../../AdminPannel/Directive/Header/Index'

const leaveAttendanceData=[
    {
        student:"Shivam",
        status:"Approve",
        date:"21-09-2023"
    },
    {
        student:"Shivam",
        status:"Approve",
        date:"22-09-2023"
    },
    {
        student:"Shivam",
        status:"Approve",
        date:"23-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"24-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"25-09-2023"
        
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"26-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"27-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"21-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"22-09-2023"
    },
    {
        student:"Shivam",
        status:"Disapprove",
        date:"23-09-2023"
    },

    {
        student:"Shivam",
        status:"Approve",
        date:"24-09-2023"
    },
    {
        student:"Shivam",
        status:"Approve",
        date:"25-09-2023"
    },
    {
        student:"Shivam",
        status:"Approve",
        date:"26-09-2023"

    },
    {
        student:"Shivam",
        status:"Approve",
        date:"26-09-2023"

    },
    {
        student:"Shivam",
        status:"Approve",
        date:"27-09-2023"

    },
    {
        student:"Shivam",
        status:"Approve",
        date:"27-09-2023"

    },
    {
        student:"Shivam",
        status:"Approve",
        date:"26-09-2023"
    },
]
export default function LeaveManagement() {
    return (
    
                    <div className="main-content">
                        <Navbar title="Leave Management" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='leaveAttendanceBox'>
                                        <table>
                                            <tr>
                                                <th className="fw-bold" style={{fontSize:"20px"}}>Sr. No.</th>
                                                <th className="fw-bold" style={{fontSize:"20px"}}>Student</th>
                                                <th className="fw-bold" style={{fontSize:"20px"}}>Date</th>
                                                <th className="fw-bold" style={{fontSize:"20px"}}>Status</th>
                                                <th className="fw-bold" style={{fontSize:"20px"}}>Action</th>
                                            </tr>
                                            {
                                                leaveAttendanceData?.map((item,index)=>{
                                                    return(
                                                        <>
                                                        <tr>
                                                            <td>{index+1}.</td>
                                                            <td>{item?.student}</td>
                                                            <td>{item?.date}</td>
                                                            <td className={`${item?.status === 'Approve' ? 'present' : 'absent'}`}>{item?.status}</td>
                                                            <td>
                                                                <div>
                                                                    <button className='presentbtn'>Approve</button>
                                                                    <button className='absentbtn'>Disapprove</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                            <tr></tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
       
    )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { apiInstance, apiInstanceFetch } from "../../../api/axiosApi";
import { SetDevKey, setToken } from "../../../util/setAuth";
import { secretKey } from "../../../util/config";

import { DangerRight, Success } from "../../../api/toastServices";

const initialState = {
  studentData: {},
  isAuth: false,
  isLoading: false,
};


export const signUpStudent = createAsyncThunk("users/addUser", async (payload) => {
  return apiInstance.post("users/addUser", payload);
});

export const loginStudent = createAsyncThunk("/loginUser", async (payload) => {
  return apiInstance.post("/users/loginUser", payload);
});

const studentAuthSlice = createSlice({
  name: "studentAuthSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token = action.payload;
      state.studentData = token;
      state.isAuth = true;
      SetDevKey(secretKey);
      setToken(token);
    },
    logout(state, action) {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_");
      localStorage.removeItem("key");
      localStorage.removeItem("isAuth");
      state.studentData = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpStudent.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(signUpStudent.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        state.isAuth = true;
        state.studentData=action.payload.data
        console.log("action.payload.token", action.payload.data);
        localStorage.setItem("studentData", JSON.stringify(action.payload.data));
        // localStorage.setItem("key", secretKey ? secretKey : undefined);
        localStorage.setItem("isAuth", true);
        Success("Register Student successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(signUpStudent.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(loginStudent.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(loginStudent.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        state.isAuth = true;
        state.studentData=action.payload.data
        console.log("action.payload.token", action.payload.data);
        localStorage.setItem("studentData", JSON.stringify(action.payload.data));
        // localStorage.setItem("key", secretKey ? secretKey : undefined);
        localStorage.setItem("isAuth", true);
        Success("Login Student successfully");
        setTimeout(() => {
          window.location.href='admin/dashboard'
        }, 1000);
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(loginStudent.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default studentAuthSlice.reducer;
export const { setOldAdmin, logout } = studentAuthSlice.actions;


import { createSlice } from "@reduxjs/toolkit"
import { apiInstanceFetch } from "../../../api/axiosApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
    allCourse:[],
    isLoading:false,
}
export const allCourseList = createAsyncThunk("/course",async (payload) => {
    return (apiInstanceFetch.get("/course"))
})

const StudentcourseSclie = createSlice({
    name:"course",
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(allCourseList.pending,(state,action) =>
      {  builder.isLoading = true;
    state.allCourse = action.payload.data.course})
    }
})

export default StudentcourseSclie.reducers
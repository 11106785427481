import React, { useState } from 'react';
import '../../../../assests/css/calander.css';

const CalendarCom = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);

  const renderHeader = () => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return (
      <div className="calendar-header">
        <span>{monthNames[currentMonth]} {currentYear}</span>
        <div className='leftSideBtn'>
        <button onClick={prevMonth}>&#8249;</button>
        <button onClick={nextMonth}>&#8250;</button>
        </div>
      </div>
    );
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
    setSelectedDay(null); // Reset selected day when month changes
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
    setSelectedDay(null); // Reset selected day when month changes
  };

  const renderDays = () => {
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return (
      <div className="calendar-days">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="calendar-day-name">
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderDates = () => {
    const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const startDay = monthStart.getDay();
    const daysInMonth = monthEnd.getDate();

    const dates = [];
    for (let i = 0; i < startDay; i++) {
      dates.push(<div key={`empty-${i}`} className="calendar-date empty" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(
        <div key={day} className={`calendar-date ${selectedDay === day ? 'selected' : ''}`} onClick={() => setSelectedDay(day)}>
          {day}
        </div>
      );
    }

    return <div className="calendar-dates">{dates}</div>;
  };

  return (
    <div className="calendar-container">
      {renderHeader()}
      {renderDays()}
      {renderDates()}
    </div>
  );
};

export default CalendarCom;


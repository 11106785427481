const DashboardCard = ({title , img, count ,className ,borderColor}) => {
    return (
        <div className={`card h-100 d-flex flex-column border-0`} style={{backgroundColor:className}} >
          <div className="card-body d-flex flex-column justify-content-center">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="rounded d-flex justify-content-center align-items-center w-100 h-100">
                  <img 
                    src={img} 
                    alt="Dashboard illustration" 
                    className="img-fluid" 
                    style={{
                      width: '100%',
                      height: '100%',
                      maxWidth: '200px',
                      maxHeight: '150px',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div>
                <p className="text-dark font-bold h4 mb-2 d-inline-flex justify-content-center align-items-center rounded-circle" style={{ width: '60px', height: '60px', border:`1px solid ${borderColor}` }}>{count}</p>
                  <p className="h4 fw-semibold text-dark mt-2">{title}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    )
}

export default DashboardCard;
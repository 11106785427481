import React from 'react';
import "./myorders.css";
import Navbar from "../Directive/Header/Index"

const MyOrders = () => {
  return (
    <>
        {/* <section className='myOrder-sec1'>
        <div className='book-order'>
        <i class="fa-regular fa-calendar-days"></i>
        </div>
        <div className='Order-detail'>
            <table>
                <tbody>
                    <tr>
                        <th>Order ID</th>
                        <th>Course Name</th>
                        <th>Date</th>
                        <th>Price</th>
                        <th>Invoice</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        </section> */}
        
        <div className="main-content">
        <Navbar title="My Orders" />
        <section className='myOrder-sec1 '>
        <div className='book-order'>
        <i class="fa-regular fa-calendar-days"></i>
        </div>
        <div className='Order-detail'>
            <table>
                <tbody>
                    <tr>
                        <th>Order ID</th>
                        <th>Course Name</th>
                        <th>Date</th>
                        <th>Price</th>
                        <th>Invoice</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Python</td>
                        <td>10/09/2023</td>
                        <td>10,000/-</td>
                        <td><i class="fa-solid fa-download"></i></td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        </section>
        </div>
    </>
  )
}

export default MyOrders
import paython from "../../../../assests/images/python.png";
import { ReactComponent as QuestionLIstIcon } from "../../../../assests/images/questionLIstIcon.svg";
import { ReactComponent as StarIcStarRatingIconon } from "../../../../assests/images/starRatingIcon.svg";
import {ReactComponent as ClockIcon} from "../../../../assests/images/clockQueizzesIcon.svg";
import { useNavigate } from "react-router-dom";
const SingleQuizzes = () => {
    const navigate = useNavigate()
    const  handelOpenQuestion = () => {
        navigate("/admin/question")
    }
   return (
    <div class="quiz-card ">
      <div class="quiz-header ">
        <div className="row w-100">
          <div className="col-1 d-flex align-items-center justify-content-center">
            <div className="queizImage">

            <img src={paython} alt="Python Logo" className="w-100 h-100"/>
            </div>
          </div>
          <div className="col-11">
            <div className="d-flex justify-content-between align-items-center">

            <h5 class="mb-0">Python</h5>
            <p class="star-rating ml-auto d-flex align-items-center">
              <StarIcStarRatingIconon /> 4.5
            </p>
            </div>
            <div class="quiz-info mt-3 sessionBorder py-2">
              <div className="d-flex align-items-center gap-2">
                <QuestionLIstIcon />
                <p class="mb-0 text-start">20 <br/> Questions</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <ClockIcon />
                <p class="mb-0 text-start">30 <br/> Mins</p>
              </div>
              <div className="d-flex align-items-center gap-2">
              <QuestionLIstIcon />

                <p class="mb-0 text-start">30 <br/> Marks</p>
              </div>

            </div>
            <div className="d-flex sessionBorder py-2 justify-content-between align-items-center">

        <diV className="d-flex align-items-center gap-2">
        <QuestionLIstIcon />
                <p class="">English, Hindi +6 more</p>

        </diV>
             
            <div class="d-flex gap-2 justify-content-end">
              <button class="btn profileButton mr-2 text-white px-4">
                Preview
              </button>
              <button class="btn profileButton text-white px-4" onClick={handelOpenQuestion}>
                Start Now
              </button>
            </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleQuizzes;
